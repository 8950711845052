import { useEffect, useRef } from "react";
//import UserMiddleWare from "../../components/middlewares/UserMiddleWare";
//import PanelTemplateLoading from "../../components/PanelTemplate/PanelTemplateLoading";
//import PanelTemplateError from "../../components/PanelTemplate/PanelTemplateError";
import LessonSlider from "../../components/Lesson/LessonSlider";
import LessonMessageBlock from "../../components/Lesson/LessonMessageBlock";
//import DisciplineUserFileUploader from "../../components/Discipline/DisciplineUserFileUploader";


// eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import 'video.js/dist/video-js.css';
import constant from "../../utils/GlobalValues";

/*
class LessonPageClass extends Component {

    constructor(props){
        super(props);
        this.state = {
            lessonId : props.lessonId,
            numberOfLesson: props.numberOfLesson,

            courseId : props.courseId,
            disciplineId : props.disciplineId,

            isLoading : false,
            isError : false,
            isFetching : false,

            lessonViewModel : null
        }

        this.getLesson = this.getLesson.bind(this);
    }

    getLesson(lessonId, disciplineId, courseId){
        this.setState({isLoading : true, isError : false});
        const userMiddleWare = new UserMiddleWare();
        const jwt = userMiddleWare.getJWTFromCookie();
        const data = {
            lessonId : parseInt(lessonId, 10),
            disciplineId : parseInt(disciplineId, 10),
            courseId : parseInt(courseId, 10)
        }
        fetch(constant.url + "/api/lesson/user/get", 
            {
                method : "POST",
                headers : {
                  "Authorization" : "Bearer " + jwt,
                  'Content-Type': 'application/json'
                },
                body : JSON.stringify(data)
            }
        )
        .then(res => res.json())
        .then((result) => {
            this.setState({isLoading : false, isError : false});
            console.log(result);
            if(result.status === "success" && result.lessonViewModel !== null){
                this.setState({
                    warning : "",
                    lessonViewModel : result.lessonViewModel
                });
            } else {
                this.setState({isError : true});
            }
            },
            (error) => {
                this.setState({isLoading : false, isError : true});
            }
        )
    }

    componentDidMount(){
        this.getLesson(this.props.lessonId, this.props.disciplineId, this.props.courseId);
    }

    render(){

        if(this.state.isError){
            return(
                <div className="row">
                    <PanelTemplateError tryAgainDelegate={() => this.getLesson(this.props.lessonId, this.props.disciplineId, this.props.courseId)} />
                </div>
            )
        }

        if(this.state.isLoading || (!this.state.isError && this.state.lessonViewModel === null)){
            return(
                <div className="row">
                    <PanelTemplateLoading />
                </div>
            )
        }

        return(
            <>
                <div className="col-12">
                    <h3>{this.props.numberOfLesson} занятие: {this.state.lessonViewModel.name}</h3>
                </div>
            </>
        )
    }
}
*/

export default function LessonPage(props)
{
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const options = {
        autoplay : false,
        //playbackRates: [0.5, 1, 1.25, 1.5, 2],
        //width: 720,
        //height: 300,
        controls: true,
        sources : [
            {
                src: "https://api.study.professorstoday.ru" + props.lessonViewModel.video_src,
                type: 'application/x-mpegURL',
            },
        ]
    };

    var videoContent = "";
    if(props.lessonViewModel.videoType === 0 && props.lessonViewModel.videoYoutubeLink !== null)//props.lessonViewModel.videoType === 0 && 
    {
        videoContent = <iframe src={'https://www.youtube.com/embed/' + props.lessonViewModel.videoYoutubeLink}
            frameBorder='0'
            width="100%"
            height="320px"
            allow='encrypted-media'
            allowFullScreen
            title={props.lessonViewModel.name}
        />
    } else if(props.lessonViewModel.videoType === 1 && typeof(props.lessonViewModel.video_src) !== "undefined" && props.lessonViewModel.video_src !== null)
    {
        videoContent = <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    }

    var docsLinks = <p>- не предусмотрено -</p>;
    if(props.lessonViewModel.docs !== null && props.lessonViewModel.docs.length > 0)
    {
        docsLinks = props.lessonViewModel.docs.map((docPath, index) => {

            return <li key={index}><a href={constant.url + docPath} target="download">{docPath.split('/').pop()}</a></li>
        });
    }

    useEffect(() => {
        if(props.lessonViewModel.videoType === 1 && typeof(props.lessonViewModel.video_src) !== "undefined" && props.lessonViewModel.video_src !== null)
        {
            if (!playerRef.current) 
            {
                const videoElement = document.createElement("video-js");

                videoElement.classList.add('vjs-big-play-centered');
                videoRef.current.appendChild(videoElement);

            
                playerRef.current = videojs(videoElement, options, () => {
                    //videojs.log('player is ready');
                    //onReady && onReady(player);
                });
            } else 
            {
                const player = playerRef.current;

                player.autoplay(false);
                player.src(options.sources);
            }
        }
    }, [videoRef]);//options

    useEffect(() => {
        if(props.lessonViewModel.videoType === 1 && typeof(props.lessonViewModel.video_src) !== "undefined" && props.lessonViewModel.video_src !== null)
        {
            const player = playerRef.current;

            return () => {
                if (player)//&& !player.isDisposed() 
                {
                    player.dispose();
                    playerRef.current = null;
                }
            };
        }
    }, [playerRef]);

    //console.log("numberOfLesson: " + props.numberOfLesson);
    //console.log("props.lessonViewModel.videoYoutubeLink: " + props.lessonViewModel.videoYoutubeLink);

    return (
        <>
            <div className="col-12">
                <h4>{props.numberOfLesson} занятие{props.lessonViewModel.name !== null ? ": " + props.lessonViewModel.name : ""}</h4>
            </div>

            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                <p className="title">Видео</p>
                {videoContent}
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                <LessonSlider sliders={props.lessonViewModel.sliders} />
            </div>
            <div className="col-12 clearfix"></div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                <LessonMessageBlock
                courseId={props.courseId}
                disciplineId={props.disciplineId}
                lessonId={props.lessonViewModel.id}
                countOfUnreadedMessagesFromUser={props.countOfUnreadedMessagesFromUser} 
                 />
            </div>
            <div className="col-12 col-lg-6 col-md-6 col-sm-12">
                <div className="faq">
                    <h4>Часто задаваемые вопросы</h4>
                    <p>- список пуст -</p>
                </div>
            </div>
            
            <div className="col-12 links">
                <h3 className="title">Материалы</h3>
            </div>
            <div className="col-3 d-none d-md-block"></div>
            <div className="col-12 col-lg-9 col-md-9 links">
                {docsLinks}
            </div>
        </>
    )
}

/*


    <DisciplineUserFileUploader
        courseId={props.courseId}
        disciplineId={props.disciplineId}
        lessonId={props.lessonViewModel.id}
    />

*/