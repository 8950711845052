


export default function TestQuestionAnswerView(props) {

    const {answerQuestionDelegate} = props;

    return (
        <div className="answer">
            <label htmlFor={"answer" + props.id}><input type="checkbox" id={"answer" + props.id} onChange={answerQuestionDelegate} value={props.id} checked={props.isChecked ? true: false} /> {props.numberOfAnswer}) {props.textContent} </label>
        </div>
    )
}