const IndexDesciption = {
    fontFamily: "Times New Roman",
    fontSize: "14px",
    lineHeight: "20px",
}



export const styles = {
    IndexDesciption : IndexDesciption
}