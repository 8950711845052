import constant from "../utils/GlobalValues";


export default class SurveyService
{
    async editWithFileByUser(
        jwt,
        setIsLoading, 
        setWarning,
        setSuccess,
        surveyId,
        surveyQuestionId,
        uploadedFile,
        successCallback
    ){
        setIsLoading(true);
        setWarning("");
        
        const formData = new FormData();
        formData.append("surveyQuestionId", surveyQuestionId);
        formData.append("uploadedFile", uploadedFile);

        try
        {
            await fetch(constant.url + "/api/survey/user/" + surveyId + "/file",
            {
                method: 'POST',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    //'Content-Type': 'application/json'
                },
                body: formData,
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    //setSurveyUserViewModel(jsonAnswerStatus.surveyUserViewModel);
                    //setSuccess("Успешно сохранено");
                    if(successCallback !== null)
                    {
                        successCallback();
                    } else 
                    {
                        setSuccess("Успешно сохранено");
                    }
                } else
                {
                    setWarning("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                console.log(error);
                setWarning("Неизвестная ошибка на сервере");
            })
        } catch(error)
        {
            console.log(error);
            setWarning("Неизвестная ошибка на сервере");
        } finally
        {
            setIsLoading(false);
        }
    }
    
    async deleteFileByUser(
        jwt,
        setIsLoading, 
        setWarning,
        setSuccess,
        surveyId,
        surveyQuestionId,
        successCallback
    ){
        setIsLoading(true);
        setWarning("");
        try
        {
            await fetch(constant.url + "/api/survey/user/" + surveyId + "/file/" + surveyQuestionId,
            {
                method: 'DELETE',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    //'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    //setSurveyUserViewModel(jsonAnswerStatus.surveyUserViewModel);
                    if(successCallback !== null)
                    {
                        successCallback();
                    } else
                    {
                        setSuccess("Успешно сохранено");
                    }
                } else
                {
                    setWarning("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                console.log(error);
                setWarning("Неизвестная ошибка на сервере");
            })
        } catch(error)
        {
            console.log(error);
            setWarning("Неизвестная ошибка на сервере");
        } finally
        {
            setIsLoading(false);
        }
    }


    async editByUser(
        jwt,
        setIsLoading, 
        setWarning,
        setSuccess,
        surveyId,
        surveyEditByUser
    ){
        setIsLoading(true);
        setWarning("");

        
        //const formData = new FormData();
        //formData.append("id", surveyEditByUser["id"]);
        //formData.append("surveyQuestions", JSON.stringify(surveyEditByUser["surveyQuestions"]));

        
        //const formDataSurveyQuestions = new FormData();
        //formData.append("surveyQuestions", surveyEditByUser["surveyQuestions"]);
        /*
        for (var key in surveyEditByUser["surveyQuestions"])
        {
            //console.log(key, surveyEditByUser["surveyQuestions"][key])
            //formDataSurveyQuestions.append(key, surveyEditByUser["surveyQuestions"][key]);
            formData.append("surveyQuestions[]", JSON.stringify(surveyEditByUser["surveyQuestions"][key]));
        }
        //formData.append("surveyQuestions[]", formDataSurveyQuestions);
        */
        

        try
        {
            await fetch(constant.url + "/api/survey/user/" + surveyId,
            {
                method: 'POST',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(surveyEditByUser),
                //body: formData,
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success")
                {
                    //setSurveyUserViewModel(jsonAnswerStatus.surveyUserViewModel);
                    setSuccess("Успешно сохранено");
                } else
                {
                    setWarning("Неизвестная ошибка на сервере");
                }
            })
            .catch((error) => {
                console.log(error);
                setWarning("Неизвестная ошибка на сервере");
            })
        } catch(error)
        {
            console.log(error);
            setWarning("Неизвестная ошибка на сервере");
        } finally
        {
            setIsLoading(false);
        }
    }


    async getForUser(
        jwt,
        setIsLoading, 
        setIsError,
        surveyId,
        setSurveyUserViewModel,
        setSurveyForm
    ){
        setIsLoading(true);
        setIsError(false);
        try
        {
            await fetch(constant.url + "/api/survey/user/" + surveyId,
            {
                method: 'GET',
                headers: {
                    'Authorization' : 'Bearer ' + jwt,
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer'
            })
            .then(res => res.json())
            .then((jsonAnswerStatus) => {
                if(jsonAnswerStatus.status === "success" && jsonAnswerStatus.surveyUserViewModel !== null)
                {
                    setSurveyUserViewModel(jsonAnswerStatus.surveyUserViewModel);
                    if(setSurveyForm !== null)
                    {
                        const surveyFormTemp = {};
                        if(jsonAnswerStatus.surveyUserViewModel.surveyQuestionUserViewModels !== null)
                        {
                            jsonAnswerStatus.surveyUserViewModel.surveyQuestionUserViewModels.forEach(surveyQuestionUserViewModel => {
                                if(surveyQuestionUserViewModel.is_list_of_checkboxes === 1)
                                {
                                    if(surveyQuestionUserViewModel.user_answer !== null)
                                    {
                                        surveyFormTemp["field_" + surveyQuestionUserViewModel.id] = new Set(surveyQuestionUserViewModel.user_answer.split(","));
                                    }
                                } else 
                                {
                                    surveyFormTemp["field_" + surveyQuestionUserViewModel.id] = surveyQuestionUserViewModel.user_answer;
                                }
                            });
                        }
                        setSurveyForm(surveyFormTemp);
                    }
                } else
                {
                    setIsError(true);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsError(true);
            })
        } catch(error)
        {
            console.log(error);
            setIsError(true);
        } finally
        {
            setIsLoading(false);
        }
    }
}