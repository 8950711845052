import { useParams } from "react-router-dom";
import UserMiddleWare from "../../components/middlewares/UserMiddleWare";
import SurveyService from "../../services/SurveyService";
import { useEffect, useState } from "react";
import { SystemLoadingPage } from "../SystemLoading/SystemLoadingPage";
import { SystemErrorPage } from "../SystemError/SystemErrorPage";
import { Button } from "react-bootstrap";
import constant from "../../utils/GlobalValues";


export default function SurveyUserPage(props)
{
    const {survey_id} = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [isError, setIsError] = useState(false);
    const [surveyUserViewModel, setSurveyUserViewModel] = useState(null);
    const surveyService = new SurveyService();
    const [surveyForm, setSurveyForm] = useState({});
    //const [surveyFormSet, setSurveyFormSet] = useState(() => new Set());

    const [isSaving, setIsSaving] = useState(false);
    const [warning, setWarning] = useState("");
    const [success, setSuccess] = useState("");

    const surveyGetForUser = async () => {
        const userMiddleware = new UserMiddleWare();
        const jwt = userMiddleware.getJWTFromCookie();
        await surveyService.getForUser(
            jwt,
            setIsLoading,
            setIsError,
            survey_id,
            setSurveyUserViewModel,
            setSurveyForm
        );
    }

    const handleForm = (e) => {
        const surveyFormTemp = surveyForm;
        surveyFormTemp[e.target.name] = e.target.value;
        setSurveyForm(() => ({
            ...surveyFormTemp
        }));
        setWarning("");
        setSuccess("");
        console.log(surveyForm);
    }

    const handleFormCheckbox = (e) => {
        const surveyFormTemp = surveyForm;
        if(typeof(surveyFormTemp[e.target.name]) === "undefined" || surveyFormTemp[e.target.name] === null)
        {
            console.log("Добавление Set");
            surveyFormTemp[e.target.name] = new Set();
        }
        
        if(e.target.checked)
        {
            surveyFormTemp[e.target.name].add(e.target.value);
        } else
        {
            surveyFormTemp[e.target.name].delete(e.target.value);
        }
        setSurveyForm(() => ({
            ...surveyFormTemp
        }));
        setWarning("");
        setSuccess("");
        console.log(surveyForm);
    }

    const handleFormFile = async(e) => {
        if(e.target.files[0] === null)
        {
            return;
        }

        const userMiddleware = new UserMiddleWare();
        const jwt = userMiddleware.getJWTFromCookie();
        await surveyService.editWithFileByUser(
            jwt,
            setIsSaving,
            setWarning,
            setSuccess,
            survey_id,
            e.target.name.replace("field_", ""),
            e.target.files[0],
            surveyGetForUser
        )
    }

    const deleteFile = async(surveyUserActionId) => {
        const userMiddleware = new UserMiddleWare();
        const jwt = userMiddleware.getJWTFromCookie();
        await surveyService.deleteFileByUser(
            jwt,
            setIsSaving,
            setWarning,
            setSuccess,
            survey_id,
            surveyUserActionId,
            surveyGetForUser
        )
    }

    const handleFormSubmit = async (event) => {
        event.preventDefault()
        //console.log(event.target.length)
        //console.log(event.target[0].name)
        //console.log(event.target[0].value)
        //console.log(event.target.elements.field_1.value);
        //console.log(event.target.elements.username.value)
        //console.log(event.target.field_1.value)
        //console.log(this.inputNode.value)

        const surveyEditByUser = {
            id: survey_id
        }
        const surveyQuestions = [];
        Object.keys(surveyForm).forEach(function(key) 
        {
            console.log(key, surveyForm[key]);
            const idStr = key.replace("field_", "")
            if(typeof(surveyForm[key]) === "object")
            {
                if(surveyForm[key] !== null)
                {
                    surveyQuestions.push({
                        id: idStr,
                        valueAsList: Array.from(surveyForm[key])
                    });
                }
            } else
            {
                surveyQuestions.push({
                    id: idStr,
                    value: surveyForm[key]
                });
            }
        });
        surveyEditByUser["surveyQuestions"] = surveyQuestions;

        const userMiddleware = new UserMiddleWare();
        const jwt = userMiddleware.getJWTFromCookie();
        await surveyService.editByUser(
            jwt,
            setIsSaving,
            setWarning,
            setSuccess,
            survey_id,
            surveyEditByUser
        )
    }

    useEffect(() => {

        const userMiddleware = new UserMiddleWare();
        const jwt = userMiddleware.getJWTFromCookie();

        async function get()
        {
            await surveyService.getForUser(
                jwt,
                setIsLoading,
                setIsError,
                survey_id,
                setSurveyUserViewModel,
                setSurveyForm
            );
        }
        get();

        return () => {
            //console.log("useEffect empty");
        }
    }, [])

    if(isLoading)
    {
        return (
            <SystemLoadingPage />
        )
    }

    if(!isLoading && (isError || surveyUserViewModel === null))
    {
        return <SystemErrorPage tryAgain={surveyGetForUser} />
    }

    let surveyQuestionNumber = 0;
    let surveyQuestionUsers = <p> Данных не найдено </p>;
    if(surveyUserViewModel.surveyQuestionUserViewModels !== null && surveyUserViewModel.surveyQuestionUserViewModels.length > 0)
    {
        surveyQuestionUsers = surveyUserViewModel.surveyQuestionUserViewModels.map(surveyQuestionUserViewModel => {
            surveyQuestionNumber += 1;

            if(surveyQuestionUserViewModel.is_need_content === 1)
            {
                return <div className="form-group row" key={surveyQuestionUserViewModel.id}>
                    <label className="control-label col-12" htmlFor={"field_" + surveyQuestionUserViewModel.id}>{surveyQuestionNumber}) {surveyQuestionUserViewModel.content}</label>
                    <div className="col-12">
                        <textarea className="form-control" rows={5} 
                        name={"field_" + surveyQuestionUserViewModel.id} 
                        onChange={handleForm}
                        defaultValue={surveyQuestionUserViewModel.user_answer}
                        ></textarea>
                    </div>
                </div>
            }

            if(surveyQuestionUserViewModel.is_need_file === 1)
            {
                let linkToFile = "";
                if(typeof(surveyQuestionUserViewModel.user_file_src) !== "undefined" 
                    && surveyQuestionUserViewModel.user_file_src !== null && surveyQuestionUserViewModel.user_file_src !== ""
                ){
                    linkToFile = <ul><li><a href={constant.url + surveyQuestionUserViewModel.user_file_src} target="_blank" rel="noreferrer">Загруженный файл</a> - <Button variant="danger" size="sm" onClick={() => deleteFile(surveyQuestionUserViewModel.id)}>Удалить</Button> </li></ul>
                }

                const questionContent = () => {
                    return {__html: surveyQuestionNumber + ") " + surveyQuestionUserViewModel.content}
                }

                return <div className="form-group row" key={surveyQuestionUserViewModel.id}>
                    <label className="control-label col-12" htmlFor={"field_" + surveyQuestionUserViewModel.id} dangerouslySetInnerHTML={questionContent()} />
                    <div className="col-12">
                        <input type="file" className="form-control" 
                        accept="image/jpeg,image/jpg,image/png,image/gif,image/bmp"
                        name={"field_" + surveyQuestionUserViewModel.id}
                        onChange={handleFormFile}
                        />
                    </div>
                    {linkToFile}
                </div>
            }

            if(surveyQuestionUserViewModel.is_list_of_checkboxes === 1)
            {
                let surveyQuestionCheckboxUsers = "";
                if(surveyQuestionUserViewModel.surveyQuestionCheckboxUserViewModels !== null && surveyQuestionUserViewModel.surveyQuestionCheckboxUserViewModels.length > 0)
                {
                    //const userAnswersArray = surveyQuestionUserViewModel.user_answer !== null 
                    //? surveyQuestionUserViewModel.user_answer.split(",")
                    //: [];
                    //console.log(surveyForm);

                    surveyQuestionCheckboxUsers = surveyQuestionUserViewModel.surveyQuestionCheckboxUserViewModels.map(surveyQuestionCheckboxUserViewModel => {
                        let checked = false;
                        if(typeof(surveyForm["field_" + surveyQuestionUserViewModel.id]) !== "undefined" 
                            && surveyForm["field_" + surveyQuestionUserViewModel.id] !== null
                        ){
                            //console.log(surveyForm["field_" + surveyQuestionUserViewModel.id]);
                            //console.log(surveyForm["field_" + surveyQuestionUserViewModel.id].split(","));
                            //checked = surveyForm["field_" + surveyQuestionUserViewModel.id].split(",").includes(surveyQuestionCheckboxUserViewModel.id.toString());
                            checked = surveyForm["field_" + surveyQuestionUserViewModel.id].has(surveyQuestionCheckboxUserViewModel.id.toString());
                        }

                        return <div className="form-check" key={surveyQuestionCheckboxUserViewModel.id}>
                            <input className="form-check-input" type="checkbox" 
                                name={"field_" + surveyQuestionUserViewModel.id} 
                                defaultValue={surveyQuestionCheckboxUserViewModel.id} 
                                onChange={handleFormCheckbox} 
                                //checked={userAnswersArray.includes(surveyQuestionCheckboxUserViewModel.id)}
                                checked={checked}
                            />
                            <label className="form-check-label" htmlFor={"field_" + surveyQuestionCheckboxUserViewModel.id} >
                            {surveyQuestionCheckboxUserViewModel.content}
                            </label>
                        </div>
                    });
                }
                return <div className="form-group row" key={surveyQuestionUserViewModel.id}>
                    <label className="control-label col-12" htmlFor={"field_" + surveyQuestionUserViewModel.id}>{surveyQuestionNumber}) {surveyQuestionUserViewModel.content}</label>
                    <div className="col-12">
                        {surveyQuestionCheckboxUsers}
                    </div>
                </div>
            }

            if(surveyQuestionUserViewModel.is_list_of_radios === 1)
            {
                let surveyQuestionRadioUsers = "";
                if(surveyQuestionUserViewModel.surveyQuestionRadioUserViewModels !== null && surveyQuestionUserViewModel.surveyQuestionRadioUserViewModels.length > 0)
                {
                    surveyQuestionRadioUsers = surveyQuestionUserViewModel.surveyQuestionRadioUserViewModels.map(surveyQuestionRadioUserViewModel => {
                        let checked = false;
                        if(surveyForm["field_" + surveyQuestionUserViewModel.id] !== null)
                        {
                            checked = surveyForm["field_" + surveyQuestionUserViewModel.id] === surveyQuestionRadioUserViewModel.id.toString();
                        }

                        return <div className="form-check" key={surveyQuestionRadioUserViewModel.id}>
                            <input className="form-check-input" type="radio" 
                                name={"field_" + surveyQuestionUserViewModel.id} 
                                value={surveyQuestionRadioUserViewModel.id} 
                                onChange={handleForm} 
                                checked={checked}
                             />
                            <label className="form-check-label" >
                            {surveyQuestionRadioUserViewModel.content}
                            </label>
                        </div>
                    });
                }
                return <div className="form-group row" key={surveyQuestionUserViewModel.id}>
                    <label className="control-label col-12" htmlFor={"field_" + surveyQuestionUserViewModel.id}>{surveyQuestionNumber}) {surveyQuestionUserViewModel.content}</label>
                    <div className="col-12">
                        {surveyQuestionRadioUsers}
                    </div>
                </div>
            }

            return <div className="form-group row" key={surveyQuestionUserViewModel.id}>
                <label className="control-label col-12" htmlFor={"field_" + surveyQuestionUserViewModel.id}>{surveyQuestionNumber}) {surveyQuestionUserViewModel.content}</label>
            </div>
        });
    }

    return (
        <div className="container">
            <div className="row page-survey">
                <div className="col-12">
                    <h3>Опрос: {surveyUserViewModel.name}</h3>

                    <form className="form" onSubmit={handleFormSubmit}>
                        {surveyQuestionUsers}
                        <Button type="submit" variant="success" disabled={isSaving}>Сохранить</Button>
                        <p className="warning">{warning}</p>
                        <p className="success">{success}</p>
                    </form>
                </div>
            </div>
        </div>
    )
}