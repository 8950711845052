

function getProcents(points, neededPoints){
    var pointsResult = points * 100 / neededPoints;
    return pointsResult > 100 ? 100 : pointsResult;
}

export default function TestResultOfUserLite(props){

    const {handleMoreInfoDelegate} = props;
    
    if(props.isDone){
        const pointsInProcents = parseInt(getProcents(props.points, props.neededPoints));
        return <div className="alert alert-success with-more-info" role="alert" onClick={() => handleMoreInfoDelegate(props.testId)}>
                Тест сдан {pointsInProcents}%<br />
                <span>подробнее</span>
            </div>
    } else if(!props.isDone && props.isFinished){
        const pointsInProcents = parseInt(getProcents(props.points, props.neededPoints));
        return <div className="alert alert-danger with-more-info" role="alert" onClick={() => handleMoreInfoDelegate(props.testId)}>
                Тест не сдан {pointsInProcents}%<br />
                <span>подробнее</span>
            </div>
    } else if(!props.isFinished && props.isStarted){
        return <div className="alert alert-warning" role="alert">
                В процессе сдачи<br />
            </div>
    } else if(!props.isStarted){
        return <div className="alert alert-warning" role="alert">
                Тест не начинали<br />
            </div>
    } else {
        return (
            <>
                - ошибка -
            </>
        )
    }

}