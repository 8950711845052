

export default function PanelTemplateLoading(){

    return (
        <div className="panel-template loading">
            <p>Подождите пожалуйста, идет загрузка...</p>
        </div>
    )

}