

function getUrlMicroserviceForAuditorium(){
    if((window.location.hostname).indexOf("localhost") + 1){
        return "http://auditorium.topprofessors-local.ru";
        //return "http://localhost:8181";
    } else {
        return "https://auditorium.topprofessors.ru";
    }
}
function getUrlApi(){
    if((window.location.hostname).indexOf("localhost") + 1){
        return "http://localhost:8290";
    } else {
        //return "https://apistudy.professorstoday.ru";
        return "https://api.study.professorstoday.ru";
    }
}


const constant = {
    //"url" : "https://api.topprofessors.ru",
    "url" : getUrlApi(),
    //"url" : "http://localhost:8290",
    "urlOnlineAuditorium" : getUrlMicroserviceForAuditorium()
}

export default constant;