import { Component } from "react";
import { Link } from "react-router-dom";
import IndexPanelTitle from "../../components/IndexPanelTitle/IndexPanelTitle";

export default class VebinarsTab extends Component {

    hideHref(){
        this.props.navigate("/auditorium");
    }

    render(){
        return(
            <>
                <IndexPanelTitle title="Вебинары" />

                <div className="text-center">
                    <Link to="/auditorium">
                        <button type="button" className="btn">Онлайн аудитория</button>
                    </Link>
                    <br />
                    <br />
                </div>
                
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Дата</th>
                            <th>Тема</th>
                            <th>Ссылка</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>04.05.2024 в 17:00</td>
                            <td>О проекте Школа академического наставничества и сотворчества (Часть 1. Ток-шоу «Вектор мастерства»)</td>
                            <td><a href="https://vk.com/wall-224624874_150" target="_blank" rel="noreferrer">vk.com/wall-224624874_150</a></td>
                        </tr>
                        <tr>
                            <td>06.05.2024 в 14:00</td>
                            <td>О роли семейного, дошкольного и школьного воспитания в профессиональном наставничестве (Часть 2. Ток-шоу «Вектор мастерства»)</td>
                            <td><a href="https://vk.com/wall-224624874_152" target="_blank" rel="noreferrer">vk.com/wall-224624874_152</a></td>
                        </tr>
                        <tr>
                            <td>07.05.2024 в 11:00</td>
                            <td>О роли вузов в развитии наставничества (Часть 3. Ток-шоу «Вектор мастерства»)</td>
                            <td><a href="https://vk.com/wall-224624874_157" target="_blank" rel="noreferrer">vk.com/wall-224624874_157</a></td>
                        </tr>
                        <tr>
                            <td>08.05.2024 в 11:00</td>
                            <td>Наставничество глазами участников образовательного процесса – преподавателей и студентов (Часть 4. Ток-шоу «Вектор мастерства»)</td>
                            <td><a href="https://vk.com/wall-224624874_159" target="_blank" rel="noreferrer">vk.com/wall-224624874_159</a></td>
                        </tr>
                        <tr>
                            <td>10.05.2024 в 11:00</td>
                            <td>Концептуальные основы академического наставничества (Часть 1. Видеоисследование мнений преподавателей и студентов по вопросам наставничества)</td>
                            <td><a href="https://vk.com/wall-224624874_162" target="_blank" rel="noreferrer">vk.com/wall-224624874_162</a></td>
                        </tr>
                        <tr>
                            <td>11.05.2024 в 11:00</td>
                            <td>Роль наставничества при подготовке кадров высшей квалификации (Часть 2. Видеоисследование мнений преподавателей и студентов по вопросам наставничества)</td>
                            <td><a href="https://vk.com/wall-224624874_168" target="_blank" rel="noreferrer">vk.com/wall-224624874_168</a></td>
                        </tr>
                        <tr>
                            <td>12.05.2024 в 11:00</td>
                            <td>Организационные и отраслевые аспекты академического наставничества (Часть 3. Видеоисследование мнений преподавателей и студентов по вопросам наставничества)</td>
                            <td><a href="https://vk.com/wall-224624874_181" target="_blank" rel="noreferrer">vk.com/wall-224624874_181</a></td>
                        </tr>
                        <tr>
                            <td>13.05.2024 в 11:00</td>
                            <td>Лучшие практики наставничества (Часть 4. Видеоисследование мнений преподавателей и студентов по вопросам наставничества)</td>
                            <td><a href="https://vk.com/shans__vk?w=wall-224624874_195" target="_blank" rel="noreferrer">vk.com/shans__vk?w=wall-224624874_195</a></td>
                        </tr>
                        <tr>
                            <td>17.05.2024 В 12:00</td>
                            <td>Трансляция культурной программы очной образовательной сессии (церемония закрытия Международного кинофестиваля учебных фильмов и инновационного образовательного контента «КиноИнтеллект»)</td>
                            <td><a href="https://vk.com/shans__vk" target="_blank" rel="noreferrer">vk.com/shans__vk</a></td>
                        </tr>
                        <tr>
                            <td>18.05.2024 в 11:00</td>
                            <td>Трансляция деловой программы очной образовательной сессии</td>
                            <td><a href="https://vk.com/video-196089227_456239130?list=b864cf7eb6828c664e" target="_blank" rel="noreferrer">vk.com/video-196089227_456239130?list=b864cf7eb6828c664e</a></td>
                        </tr>
                        <tr>
                            <td>19.05.2024 в 11:00</td>
                            <td>Трансляция деловой программы очной образовательной сессии</td>
                            <td><a href="https://vk.com/video-196089227_456239131" target="_blank" rel="noreferrer">vk.com/video-196089227_456239131</a></td>
                        </tr>
                    </tbody>
                </table>

                <p>
                    <b>Ведущие вебинара:</b><br />
                    Елена Ляпунцова, руководитель проекта ШАНС <br />
                    Юлия Белозерова, генеральный продюсер эфиров и видеоконтента в рамках проекта ШАНС.<br />
                    <br />
                    Эксперты:<br />
                    <br />
                    <b>Ольга Петрова</b>, заместитель министра науки и высшего образования РФ<br />
                    <b>Владимир Татаринов</b>, советник генерального директора Фонда президентских грантов<br />
                    <b>Татьяна Ларионова</b>, депутат ГД СФ РФ, Комитет по защите семьи, вопросам отцовства, материнства и детства<br />
                    <b>Елена Приступа</b>, директор Института развития, здоровья и адаптации ребенка, профессор кафедры социальной педагогики и психологии Института педагогики и психологии МПГУ<br />
                    <b>Маргарита Филатова-Сафронова</b>, заведующая лабораторией «Технологии инклюзивного образования» в реализации проекта «Человек экологичный», доцент кафедры психологии развития и психофизиологии КИУ им. В.Г. Тимирясова<br />
                    <b>Ольга Пилипенко</b>, депутат ГД СФ РФ, Комитет по науке и высшему образованию<br />
                    <b>Альберт Сарбалаев</b>, Председатель Всероссийской общественной организации «Содружество выпускников детских домов «Дети всей страны»<br />
                    <b>Александр Горбатов</b>, Комитет по образованию Ассоциации «Деловой Центр экономического развития СНГ», профессор МИСиС<br />
                    <b>Ирина Шарова</b>, доцент кафедры предпринимательства и логистики РЭУ им. Г.В. Плеханова<br />
                    <b>Евгений Лисиченко</b>, исполнительный директор ООО «Интерактивные технологии и решения в образовании и науке»<br />
                    <b>Ярослав Судьев</b>, воспитатель<br />
                    <b>Ирина Глебова</b>, декан продюсерского факультета ГИТРа<br />
                    <b>Наталья Лазарева</b>, доктор медицинских наук, профессор кафедры Самарского ГЭУ<br />
                    <b>Елена Окунькова</b>, ректор ГБОУ ДПО «Нижегородский институт развития образования», доцент<br />
                    <b>Мария Елпидинская</b>, старший преподаватель кафедры продюсерского мастерства ГИТРа<br />
                    <b>Светлана Соловьёва</b>, проректор Тюменский ГМУ , председатель Тюменского регионального отделения лиги преподавателей высшей школы<br />
                    <b>Александр Ерохин</b>, профессор кафедры медицинской профилактики и реабилитации
                    Тюменского ГЭУ<br />
                    <b>Алексей Пожаров</b>, доцент кафедры звукорежиссуры и музыкального искусства ГИТРа<br />
                    <b>Елена Августа</b>, доцент Тюменского ГМУ. Директор Тюменского регионального отделения Лиги преподавателей высшей школы<br />
                    <b>Данила Брустовский</b>, студент ГИТРа<br />
                    <b>Александр Скоробогатов</b>, выпускник ГИТРа, креативный продюсер<br />
                    <b>Игорь Решетников</b>, аспирант<br />
                    <b>Алексей Головкин</b>, аспирант, композитор<br />
                    <b>Мария Марченко</b>, студент ГИТРа
                </p>

            </>
        )
    }

}
/*

<p>19.10.2022 11:00 - Вводная информация о курсе <i>(Е.В. Ляпунцова, Ю.М. Белозерова, Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=L0zcZXEggTw" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=L0zcZXEggTw</a></p>
<p>19.10.2022 13:00 - Психология и информационная гигиена в педагогической практике высшей школы (часть 1) <i>(М.А. Филатова-Сафронова)</i> <a href="https://www.youtube.com/watch?v=Z1Gg9EiGB8U" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=Z1Gg9EiGB8U</a></p>
<p>21.10.2022 11:00 - Организация и профессиональная этика работы преподавателя высшей школы в меняющейся информационной среде (Часть 1)<i>(С.В. Бадмаева)</i> <a href="https://www.youtube.com/watch?v=gd8Zcr5c4IQ" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=gd8Zcr5c4IQ</a></p>
<p>21.10.2022 13:00 - Эффективная работа с инструментами социальной сети «ВКонтакте» (Часть 1)<i>(Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=P58PITW_Vs8" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=P58PITW_Vs8</a></p>
<p>22.10.2022 11:00 - Практические моменты обеспечения информационной безопасности преподавателя и студентов в современных условиях<i>(Х.В. Белогорцева (Пешкова))</i> <a href="https://www.youtube.com/watch?v=N5qZLVdQQS4" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=N5qZLVdQQS4</a></p>
<p>22.10.2022 13:00 - Эффективная работа с инструментами социальной сети «ВКонтакте» (Часть 2)<i>(Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=kUt39RnbFU0" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=kUt39RnbFU0</a></p>
<p>24.10.2022 11:00 - Технология работы преподавателя в «Телеграмм»<i>(Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=RVoGx2j-W_g" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=RVoGx2j-W_g</a></p>
<p>24.10.2022 13:00 - Психология и информационная гигиена в педагогической практике высшей школы (часть 2)<i>(М.А. Филатова-Сафронова)</i> <a href="https://www.youtube.com/watch?v=SYr17nWKNCg" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=SYr17nWKNCg</a></p>

<p>27.10.2022 11:00 - Организация и профессиональная этика работы преподавателя высшей школы в меняющейся информационной среде (Часть 1)<i>(С.В. Бадмаева)</i> <a href="https://www.youtube.com/watch?v=FPGhidDefjo" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=FPGhidDefjo</a></p>

<p>27.10.2022 13:00 - Технология работы преподавателя с видеосервисом «RUTUBE»<i>(Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=qW1EL-ZLp10" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=qW1EL-ZLp10</a></p>

<p>05.11.2022 11:00 - Правовые аспекты применения информационных технологий в высшем образовании<i>(Х.В. Белогорцева (Пешкова))</i> <a href="https://www.youtube.com/watch?v=wpf1LEtxvSQ" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=wpf1LEtxvSQ</a>
<br />- Внедрение инновационных технологий в преподавательскую деятельность. мастер-класс <i>(Е.В. Ляпунцова, Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=b8-79-BoSvY" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=b8-79-BoSvY</a>
<br />- Проектная деятельность преподавателя и образовательного учреждения, метаунивер <i>(Е.В. Ляпунцова, Е.А. Лисиченко)</i> <a href="https://www.youtube.com/watch?v=g0Lldvx23jM" target="_blank" rel="noreferrer">https://www.youtube.com/watch?v=g0Lldvx23jM</a>
</p>
*/


