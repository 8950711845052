import React, {useEffect, useState}  from "react";

const UserContext = React.createContext()

const UserProvider = ({ children, valueUser }) => {
  // User is the name of the "data" that gets stored in context // { isAuthed: false }
  const [user, setUser] = useState(valueUser);

  useEffect(() => {
    //console.log("useEffect");
    //console.log(valueUser);
  });

  // Login updates the user data with a name parameter
  const login = (name) => {
    setUser((user) => ({
      isAuthed: true,
    }));
  };

  // Logout updates the user data to default
  const logout = () => {
    setUser((user) => ({
      isAuthed: false,
    }));
  };

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
}

/*
class UserProvider extends Component {
  
    // Context state
    constructor(props){
      super(props);
      this.state = {
        user: {
          isAuthed : false
        },
      }
    }

    componentDidMount(){
      //console.log("UserProvider componentDidMount");
    }
  
    // Method to update state
    setUser = (user) => {
      this.setState((prevState) => ({ user }))
    }

    setAuthed = (newIsAuthed) => {
      this.setState({user : {isAuthed : newIsAuthed}})
    }
  
    render() {
      const { children } = this.props

      const { user } = this.state
      const { setUser } = this

      return (
        <UserContext.Provider
          value={{
            user,
            setUser,
          }}
        >
          {children}
        </UserContext.Provider>
      )
    }
      
}
*/

/*
const {isAuthed} = this.state.user.isAuthed;
const { setAuthed } = this;
return (
  <UserContext.Provider
    value={{
      isAuthed,
      setAuthed,
    }}
  >
    {children}
  </UserContext.Provider>
)
*/

export { UserProvider }
//export const UserProvider = UserContext.Provider;
export const UserConsumer = UserContext.Consumer;


export default UserContext;