import { Component } from "react";
import TestQuestionAnswerView from "../TestQuestionAnswer/TestQuestionAnswerView";
import { clearSpecialSymbols } from "../../../services/SpecialSymbolCleaner";

export default class TestQuestionView extends Component {

    constructor(props){
        super(props);
        this.state = {
            isLoading : false,
            isError : false,
            idsOfTestQuestionAnswers : (props.testQuestionLiteViewModel.testQuestionAnswerIdsFilledByUserSet !== null ? new Set(props.testQuestionLiteViewModel.testQuestionAnswerIdsFilledByUserSet) : new Set())
        }
        this.setAnswer = this.setAnswer.bind(this);
        this.sendTestQuestionAnswers = this.sendTestQuestionAnswers.bind(this);
    }

    setAnswer(e){
        //console.log("setAnswer value: " + e.target.checked);
        var value = parseInt(e.target.value, 10);
        var idsOfTestQuestionAnswers = new Set(this.state.idsOfTestQuestionAnswers);
        //console.log("Текущий idsOfTestQuestionAnswers: ");
        //console.log(idsOfTestQuestionAnswers);
        console.log("e.target.checked: " + e.target.checked);
        if(e.target.checked){
            //console.log("Добавляем: " + value);
            idsOfTestQuestionAnswers.add(value);
        } else {
            //console.log("Удаляем: " + value);
            //console.log(idsOfTestQuestionAnswers.delete(value));
            idsOfTestQuestionAnswers.delete(value);
        }
        console.log("Новый idsOfTestQuestionAnswers: ");
        console.log(idsOfTestQuestionAnswers);
        this.setState({
            idsOfTestQuestionAnswers : idsOfTestQuestionAnswers
        });
    }

    sendTestQuestionAnswers(){
        //console.log(this.state.idsOfTestQuestionAnswers);
        this.props.sendTestQuestionAnswersDelegate(this.props.testQuestionLiteViewModel.id, this.state.idsOfTestQuestionAnswers);
    }
    componentDidMount(){
        //console.log("TestQuestionView componentDidMount");
        //console.log(this.state.idsOfTestQuestionAnswers);
        //console.log(this.props.testQuestionLiteViewModel);
    }



    render(){

        var testQuestionAnswerViews = "";
        if(this.props.testQuestionLiteViewModel.testQuestionAnswerLiteViewModels !== null && typeof(this.props.testQuestionLiteViewModel.testQuestionAnswerLiteViewModels) !== "undefined"){
            var testQuestionAnswerLiteViewModels = this.props.testQuestionLiteViewModel.testQuestionAnswerLiteViewModels;
            var testQuestionAnswerIdsFilledByUserSet = this.state.idsOfTestQuestionAnswers;
            //console.log("TestQuestionView testQuestionAnswerIdsFilledByUserSet:");
            //console.log(testQuestionAnswerIdsFilledByUserSet);

            if(testQuestionAnswerLiteViewModels.length > 0)testQuestionAnswerLiteViewModels.sort((a, b) => a.numberOfAnswer > b.numberOfAnswer ? 1 : -1);
    
            if(testQuestionAnswerLiteViewModels.length > 0){
                testQuestionAnswerLiteViewModels.sort((a, b) => a.numberOfAnswer > b.numberOfAnswer ? 1 : -1);
    

                testQuestionAnswerViews = testQuestionAnswerLiteViewModels.map((testQuestionAnswerLiteViewModel, index) => {
                    var isChecked = false; 
                    if(testQuestionAnswerIdsFilledByUserSet !== null){
                        //console.log("Ищем testQuestionAnswerLiteViewModel.id: " + testQuestionAnswerLiteViewModel.id + "Ответ: " + testQuestionAnswerIdsFilledByUserSet.has(testQuestionAnswerLiteViewModel.id));
                        isChecked = testQuestionAnswerIdsFilledByUserSet.has(testQuestionAnswerLiteViewModel.id);
                    }

                    return <TestQuestionAnswerView 
                    key = {testQuestionAnswerLiteViewModel.id}
                    id = {testQuestionAnswerLiteViewModel.id}
                    numberOfAnswer={testQuestionAnswerLiteViewModel.numberOfAnswer}
                    textContent={testQuestionAnswerLiteViewModel.textContent}
                    answerQuestionDelegate = {this.setAnswer}
                    isChecked = {isChecked}
                    />
                });
    
            }
        }

        return (
            <div className="question">
                <p className="all-counts">{this.props.testQuestionLiteViewModel.numberOfQuestion} / {this.props.numberOfQuestions}</p>
                <p dangerouslySetInnerHTML={{__html:clearSpecialSymbols("Вопрос №" + this.props.testQuestionLiteViewModel.numberOfQuestion + ". " + this.props.testQuestionLiteViewModel.textContent )}} />
    
                {testQuestionAnswerViews}
    
                <div className="row">
                    <div className="col-6">
                        <button type="button" className="btn" disabled={this.props.testQuestionLiteViewModel.numberOfQuestion === 1} onClick={this.props.prevQuestionDelegate}>Пред</button>
                    </div>
                    <div className="col-6 div-btn-next">
                        <button type="button" className="btn btn-default" disabled={this.state.isLoading} onClick={this.sendTestQuestionAnswers}>
                            {this.props.numberOfQuestions === this.props.testQuestionLiteViewModel.numberOfQuestion ? "Завершить" : "След"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

}